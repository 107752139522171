import React from 'react';
import { Link as MuiLink } from '@mui/material';
import './Header.css';
import logoDior from '../media/images/DiorBaby.svg';
import arrowBack from '../media/images/arrowBack.svg';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import jwt from "jwt-decode";


interface HeaderProps {
    link?: string;
    textLink?: string;
    hideLanguageSelect?: boolean;
    languageLocale?: string | null;
    wishlistUrl?: string | null;
}

function Header({ link, textLink, hideLanguageSelect = false, languageLocale = null, wishlistUrl = null }: HeaderProps) {
    //console.log(sessionStorage.getItem('locale'), 'sessionStorage.getItem(locale)');


    const { t, i18n } = useTranslation();

    let initialLanguage = i18n.language !== 'fr' && i18n.language !== 'en' ? 'en' : i18n.language;

    if(sessionStorage.getItem('locale') !== null && sessionStorage.getItem('hideLanguageSelect') !== null) {
        hideLanguageSelect = true;
        //i18n.changeLanguage(sessionStorage.getItem('locale') === '2' ? 'en' : 'fr');
    } else {
        if (languageLocale !== null) {
            initialLanguage = languageLocale;
            if (sessionStorage.getItem('locale') === null && i18n.language !== languageLocale) {
                i18n.changeLanguage(languageLocale);
            }
        }
    }

    const token = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const user: any = token === null ? null : jwt(token);
    let logoStyle: any = {};
    if(wishlistUrl !== null) {
        logoStyle = {cursor: 'pointer'};
    } else {
        if(user !== null && user !== undefined && user.role !== 3 && user.role !== 2) {
            logoStyle = {cursor: 'pointer'};
        } else {
            logoStyle = {cursor: 'default'};
        }
    }

    if(user !== null && user !== undefined) {
        if(user.locale_id !== null && user.locale_id !== undefined) {
            if(user.locale_id === 1) {
                initialLanguage = 'fr';
            } else {
                initialLanguage = 'en';
            }
        }
    }

    if(sessionStorage.getItem('locale') !== null) {
        initialLanguage = sessionStorage.getItem('locale') === '2' ? 'en' : 'fr';
    }

    const [language, setLanguage] = React.useState<string>(initialLanguage);
    const navigate = useNavigate();


    const changeLanguage = (lng: any) => {
        setLanguage(lng);
        sessionStorage.setItem('locale', lng === 'en' ? '2' : '1');
        i18n.changeLanguage(lng);
        window.location.reload();
    };

    if(i18n.language !== language) {
        if(i18n.language === 'en' || language === 'fr') {
            setLanguage(i18n.language);
        }
    }

    const handleImageClick = () => {
        if(wishlistUrl !== null) {
            navigate(wishlistUrl);
            logoStyle = {cursor: 'pointer'};
        } /*else {
            if(user !== null && user !== undefined && user.role !== 3 && user.role !== 2) {
                navigate(`/`);
            } else {
                logoStyle = {cursor: 'default'};
                return null;
            }
        }*/
    };

    //console.log(language, 'language HERE');
    //console.log(initialLanguage, 'initialLanguage HERE');
    //console.log(i18n.language, 'i18n.language HERE');
    const languageSelector = () => {
        if(hideLanguageSelect) return ('');
        console.log(i18n.language, 'i18n.language');
        return (
            <div className="LanguageContainer">
                <FormControl>
                    <Select
                        id="language-select"
                        value={i18n.language === 'fr' ? 'fr' : 'en'}
                        label="Language"
                        onChange={(event) => {
                            changeLanguage(event.target.value);
                        }}
                        sx={{
                            "& fieldset": {
                                border: "none",
                            },
                        }}
                    >
                        <MenuItem style={{fontSize: '13px', fontFamily: "Hellix !important"}} value={'en'}>EN</MenuItem>
                        <MenuItem style={{fontSize: '13px', fontFamily: "Hellix !important"}} value={'fr'}>FR</MenuItem>
                    </Select>
                </FormControl>
            </div>
        );
    }
    const logoutButton = (): JSX.Element | string => {
        if(token === null || token === undefined) {
            return '';
        } else {
            return (
                <span
                    onClick={() => {
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.href = `/login`;
                    }}
                    style={{cursor: 'pointer', fontSize: '13px', color: '#7B8487'}}
                >
                    {t('logOut')}
                </span>
            );
        }
    }

    return (
        <div >
            <div className="containerDiorLogo">
                {link && (
                    <div className="containerLinkBack">
                        <MuiLink href={link} className="linkBack" rel="noopener noreferrer" underline="none">
                            <div className="containerArrowBack">
                                <img src={arrowBack} alt="arrow back" />
                            </div>
                            <span>{t('backTo')}</span>
                        </MuiLink>
                    </div>
                )}
                <img className="LogoDior" style={logoStyle} onClick={() => {handleImageClick()}} src={logoDior} alt="Logo Dior"/>
                <div className="HeaderActions">
                    {logoutButton()}
                    {languageSelector()}
                </div>
            </div>
        </div>
    );
}

export default Header;
