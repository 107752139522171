import React, { useState, useEffect } from 'react';
import Header from '../Header';

import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';

import {useNavigate} from "react-router";

import './ProductsList.css';
import * as testProducts from '../../test-products.json';
import {checkPathName, mainRolesRoutes, rolesCodes} from "../../global";

import DeleteIcon from '../../media/images/DeleteIcon.svg';
import Modal from "react-bootstrap/Modal";
import Select from "@mui/material/Select";

import dataProvider from "../../provider/api-interface";
import jwt from "jwt-decode";
import {Loader} from "../Loader";
import {Alert, Collapse, IconButton, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import headerBackground from "../../media/images/GiftlistHeader.png";
import DiorLogo from "../../media/images/Dior.svg";

interface ProductVariation {
    sku: string;
    title: string;
    code: string;
    sizeLabel: string;
    price_value: string;
    price_currency: string;
    tracking: string;
}

interface GalleryImages {
    imageZoom: {
        uri: string;
        width: number;
        height: number;
    };
    imageThumb: {
        uri: string;
        width: number;
        height: number;
    };
}

interface ProductItem {
    id: string;
    product_code: string;
    title: string;
    subtitle: string;
    description: string;
    subUniverse: string;
    characteristics: string[];
    sizeAndFit: string;
    categoryId: string;
    image_url: string;
    galleryImages: GalleryImages[];
    variations: ProductVariation[];
}

interface Wishlist {
    id: number;
    name: string;
    shop_assistant: string;
    description: string;
    event_date: string;
    closing_date: string;
    created_at: string;
    items: any[];
    purchased_items: any[];
    user: any;
}

function ProductsList(props: any) {
    //console.log('QUI 1')
    //console.log(props)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [showModal, setShowModal] = useState<boolean>(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [maxTitleLength, setMaxTitleLength] = useState<number>(40);
    const [maxSubTitleLength, setMaxSubTitleLength] = useState<number>(20);

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>("");
    const [alertType, setAlertType] = useState<"error" | "success">("error");

    //Modal gifter message
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalProduct, setModalProduct] = useState<any>('');
    //End modal gifter message

    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };


    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    const routeType: string | undefined = props.routeType;

    const [wishlist, setWishlist] = useState<any>(props.wishlist !== null ? props.wishlist : null);
    //Use anywhere
    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    const [listCode, setListCode] = useState<string>(props.wishlist !== null ? props.wishlist.list_code : null);
    const [locale, setLocale] = useState<number>(props.locale);
    const [itemsPerPage, setItemsPerPage] = useState<number>(-1);
    const [itemsStep, setItemsStep] = useState<any>(null);

    const [products, setProducts] = useState<any>(props.products);
    const [deletedProducts, setDeletedProducts] = useState<any>([]);
    //console.log(props.products, 'products in products list')
    const [productToBeDeleted, setProductToBeDeleted] = useState<string>('');
    const [wishlistProducts, setWishlistProducts] = useState<any>(props.wishlist !== null ? props.wishlist.products : null);

    const [filtersQueryStr, setFiltersQueryStr] = useState<string>('');
    const [sortQueryStr, setSortQueryStr] = useState<string>('');
    //console.log(filtersQueryStr, 'filters query string');

    useEffect(() => {
        setFiltersQueryStr(props.filtersQueryString !== '' ? "&"+props.filtersQueryString : '');
        setSortQueryStr(props.sortQueryString !== '' ? "&sort="+props.sortQueryString : '');
        //console.log(filtersQueryStr, 'filtersQueryStr')
        setProducts(props.products);

        setIsLoading(false);
    });

    const fetchWishlist = async () => {
        console.log(routeType, 'route type')
        await dataProvider.getWishlistByListCode(listCode, jwtToken).then((response: any) => {
            setWishlist(response);
            setWishlistProducts(response.products);
            //return response;
        }).catch((error: any) => {
            console.log(error);
            //navigate(`/${pathName}/404`);
        })
    }

    /*const fetchAllProducts = async (localeId: any, items=-1) => {
        await dataProvider.get(`product/?filter={"dior_locale_id" : ${localeId},"main_category_id": ${parseInt(props.category)}}&perPage=${items}`, jwtToken).then((response: any) => {
            setProducts(response);
            handleItemsStep(response.total);
            setIsLoading(false);
            //console.log(response, 'products');
        });
    }*/

    /*useEffect(() => {
        fetchWishlist();
    }, []);*/

    const handleItemsStep = (total: number) => {
        if(total <= 10) {
            setItemsStep([total]);
            setItemsPerPage(total);
            return;
        }
        let steps = [];
        for(let i = 10; i < total; i = i + 10) {
            steps.push(i);
        }
        steps.push(total);
        setItemsStep(steps);
    }

    const checkPurchasedItem = (product: any): boolean => {
        //console.log(product, 'product to check')
        if(routeType === 'catalogue' && wishlistProducts !== null && product !== null) {
                //console.log(wishlistProducts.data, 'wishlist products');
            let addedItem = wishlistProducts.find((item: any) => item.id === product.id);
            //console.log(addedItem, 'added item');
            if(addedItem !== undefined && addedItem.gifter_id !== null) {
                return true;
            }

        } else {
            if(product.gifter_id) {
                return true;
            }
        }

        return false;
    }

    const handleRemoveItem = (productId: string) => {
        setShowModal(true);
        setProductToBeDeleted(productId);
    }

    const handleConfirmDelete = async () => {
        setIsLoading(true);
        await dataProvider.removeProductFromWishlist(wishlist.id, productToBeDeleted, jwtToken).then((response: any) => {
            if(response !== null) {
                if(routeType === 'catalogue') {
                    fetchWishlist()
                } else {
                    deletedProducts.push(productToBeDeleted);
                }
                setAlertText(t('productRemoveOk'));
                setAlertType("success");
                setAlertOpen(true);
                autoClose();

            } else {
                setAlertText(t('productRemoveKo'));
                setAlertType("error");
                setAlertOpen(true);
                autoClose();
            }
            setIsLoading(false);
            setShowModal(false);
            setProductToBeDeleted('');
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        });

    }

    const confirmDeleteItemModal= () => {
        return (<Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modalDeleteItemTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="ModalDeleteItemContainer">
                    <p>{t('modalDeleteItemParagraph')}</p>
                    <div className="ConfirmDelete" onClick={() => {handleConfirmDelete()}}>
                        <button className="Fgift-button">{t('deleteItemButton')}</button>
                    </div>
                    <div className="CancelDelete" onClick={() => {handleCloseModal(); setProductToBeDeleted('')}}>
                        <button className="Fgift-button" style={{backgroundColor: 'transparent',textDecoration: 'underline !important', color: '#33383C', border: '0px'}}>{t('cancelDeleteButton')}</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>)
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const purchasedDetailsSection = (product: any): JSX.Element | string => {
        if(user.role !== 2) { //messaggio visibile solo per giftee
            return '';
        }

        if(!product.gifter_id && !product.message_alias && !product.message_body ||
            (product.message_alias === null && product.message_body === null)) {
            return '';
        }

        return <div className="PurchasedItemContainer">
            <div className="GifterNameContainer">
                {t('giftedBy')}: {product.message_alias}
            </div>
            <div className="GiterMessageLinkContainer">
                <Link className="d-block d-lg-none" to={`/${pathName}/registry/message?wishlistId=${wishlist.list_code}&id=${product.id}`}>
                    <div className="GifterMessageLink">
                        {t('giftedLink')}
                    </div>
                </Link>
                <div
                    className="d-none d-lg-block modalGifterMessageOpener"
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        setIsModalOpen(true);
                        setModalProduct(wishlistProducts.find((item: any) => item.id === product.id));
                    }}
                >
                    <div className="GifterMessageLink">
                        {t('giftedLink')}
                    </div>
                </div>
            </div>
        </div>;
    }

    const renderMessageModal = (): JSX.Element => {
        if(modalProduct === '') return (<div></div>);
        const pageStyle: any = {
            background: `url(${headerBackground})`,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            paddingTop: '40px'
        }

        const gifter: any = modalProduct.gifter;

        return (
                <Modal
                className="right"
                show={isModalOpen}
                onHide={() => {setIsModalOpen(false)}}
                dialogClassName="fgift-custom-dialog filter-sort-modal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('giftMessage')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display:'block', maxHeight: '300px'}}>
                    <div style={pageStyle} className="MessagePageContainer">
                        <div className="row">
                            <div className="col-12">
                                <div className="MessageHeader" style={{width: '100%'}}>
                                    <div className="MessageImage">
                                        <img src={modalProduct?.image_url} />
                                    </div>
                                    <div className="MessageProductTitle">
                                        <h3>{modalProduct?.title}</h3>
                                        <p className="MessageProductSubtitle">{modalProduct?.subtitle}</p>
                                    </div>
                                </div>
                                <div className="MessageContainer" style={{width: '100%', margin: '0', marginTop: '20px'}}>
                                    <div className="MessageTextLogo">
                                        <img src={DiorLogo} />
                                    </div>
                                    <div className="MessageText">
                                        {modalProduct?.message_body}
                                    </div>
                                    <div className="row MessageSender">
                                        <div className="col-6">
                                            {gifter?.name} {gifter?.surname}
                                        </div>
                                        <div style={{textDecoration: 'underline'}} className="col-6">
                                            <Link style={{color: '#757575'}} to={`mailto:${gifter?.email}`} >{gifter?.email}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    const productPreviewTemplate = (product: ProductItem, productsArray: any): JSX.Element => {
        if("variations" in product){
            const variations = product.variations;
            let price = variations[0].price_value;

            const currency = variations[0].price_currency;

            let currencySymbol = '';
            if (currency === 'EUR') {
                currencySymbol = '€';
                price = price + ',00'
            } else if (currency === 'USD') {
                currencySymbol = '$';
                price = price + '.00'
            } else if (currency === 'GBP') {
                currencySymbol = '£';
                price = price + '.00'
            } else if (currency === 'JPY') {
                currencySymbol = '¥';
                price = price + '.00'
            } else {
                currencySymbol = 'AED ';
                price = price + '.00'
            }
            let type = routeType == 'catalogue' ? 'catalogue' : 'wishlist';
            let from = variations.length > 1 ? t('from') : '';

            let overImageSection: JSX.Element | string = '';
            let priceSection: JSX.Element | string = <div className="ProductPreviewPriceContainer">
                <div className="ProductPreviewPrice">
                    {from}
                    {currencySymbol}
                    {price}
                </div>
            </div>;

            let removeSection: any = '';
            let purchased = 0;

            if(checkPurchasedItem(product)) {
                overImageSection = <div className="Purchased">{t('productPurchased')}</div>;
                removeSection = '';
                purchased = 1;
            } else {
                if(user.role !== 3) {
                    if (
                        wishlistProducts !== null
                        && wishlistProducts.find((item: any) => item.id === product.id)
                    ) {
                        if(type === 'catalogue') {
                            overImageSection = <div className="Purchased">{t('productAdded')}</div>;
                        }
                        removeSection = <span className="CancelItemContainer" onClick={() => {
                            handleRemoveItem(product.id)
                        }}>
                        {t('removeProduct')}
                    </span>;
                    }
                }
            }

            let categoryId = props.category !== null ? `categoryId=${props.category}` : '';

            return (
                <div className="ProductPreview">
                    <div className="ProductPreviewImageContainer">
                        {overImageSection}
                        <Link to={`/${pathName}/product?id=${product.product_code}&${categoryId}&wishlistId=${wishlist.list_code}&type=${type}&purchased=${purchased}${filtersQueryStr}${sortQueryStr}`}>
                            <img onLoad={() => {
                                if(productsArray[productsArray.length - 1].id === product.id) {
                                    props.setImagesLoaded(true)
                                }
                            }} className="ProductPreviewImage img-fluid" src={product.image_url} alt={product.title} />
                        </Link>
                    </div>
                    <div className="ProductPreviewInfo">
                        <Link to={`/${pathName}/product?id=${product.product_code}&wishlistId=${wishlist.list_code}&purchased=${purchased}${filtersQueryStr}${sortQueryStr}`}>
                            <div className="ProductPreviewName">{product.title.length > maxTitleLength ? product.title.slice(0, maxTitleLength) + '...' : product.title}</div>
                        </Link>
                        <div className="ProductPreviewDescription">{product.subtitle.length > maxSubTitleLength ? product.subtitle.slice(0, maxSubTitleLength) + '...' : product.subtitle}</div>
                    </div>
                    <div className="ProductOtherInfoContainer">
                        {priceSection}
                        {removeSection}
                        {purchasedDetailsSection(product)}
                    </div>
                </div>
            );
        }else{
            return (<div></div>);
        }
    };

    const wishlistProductsTemplate = (product: any): JSX.Element => {
        //console.log(product, 'product')

        let price = product.variation.price_value;
        price = price + ',00'
        const currency = product.variation.price_currency;

        let currencySymbol = '';
        if (currency === 'EUR') {
            currencySymbol = '€';
        } else if (currency === 'USD') {
            currencySymbol = '$';
        } else if (currency === 'GBP') {
            currencySymbol = '£';
        } else if (currency === 'JPY') {
            currencySymbol = '¥';
        } else {
            currencySymbol = 'AED ';
        }
        let type = routeType == 'catalogue' ? 'catalogue' : 'wishlist';

        let overImageSection: JSX.Element | string = '';
        let priceSection: JSX.Element | string = <div className="ProductPreviewPriceContainer">
            <div className="ProductPreviewPrice">
                {currencySymbol}
                {price}
            </div>
        </div>;

        let removeSection: any = <span className="CancelItemContainer" onClick={() => {handleRemoveItem(product.id)}}>
                    {t('removeProduct')}
                </span>;

        if(user.role === 3) {
            removeSection = '';
        }

        let purchased = 0;
        if(checkPurchasedItem(product)) {
            overImageSection = <div className="Purchased">{t('productPurchased')}</div>;
            removeSection = '';
            purchased = 1;
        }

        return (
            <div className="ProductPreview" style={{height: '100%'}}>
                <div className="ProductPreviewImageContainer">
                    {overImageSection}
                    <Link to={`/${pathName}/product?id=${product.product_code}&wishlistId=${wishlist.list_code}&type=${type}&purchased=${purchased}${filtersQueryStr}${sortQueryStr}`}>
                        <img className="ProductPreviewImage img-fluid" src={product.image_url} alt={product.title} />
                    </Link>
                </div>
                <div className="ProductPreviewInfo">
                    <Link to={`/${pathName}/product?id=${product.product_code}&wishlistId=${wishlist.list_code}&purchased=${purchased}${filtersQueryStr}${sortQueryStr}`}>
                        <div className="ProductPreviewName">{product.title.length > maxTitleLength ? product.title.slice(0, maxTitleLength) + '...' : product.title}</div>
                    </Link>
                    <div className="ProductPreviewDescription">{product.subtitle.length > maxSubTitleLength ? product.subtitle.slice(0, maxSubTitleLength) + '...' : product.subtitle}</div>
                </div>
                <div className="ProductOtherInfoContainer">
                    {priceSection}
                    {removeSection}
                    {purchasedDetailsSection(product)}
                </div>
            </div>
        );
    }
    /*const selectItemsPerPage = (event: SelectChangeEvent<any>) => {
        const value = parseInt(event.target.value);
        setItemsPerPage(value);
        setIsLoading(true);
        fetchAllProducts(locale, value);
    }*/

    /*const itemsPerPageTemplate = (): JSX.Element => {
        return (
            <div className="col-12">
                <div className="ItemsPerPageContainer">
                    <Select
                        id="items-per-page"
                        defaultValue={itemsPerPage}
                        onChange={selectItemsPerPage}
                        variant={"standard"}
                    >
                        {itemsStep !== null && itemsStep.map((step: any) => (
                            <MenuItem key={step} value={step}>{step}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
        );
    }*/

    const productGridTemplate = (): JSX.Element => {
        //console.log(products, 'products');
        if(products === null || products === undefined || (products !== null && products.data === null)) return <div></div>;
        const productsArray = Object.values(products.data);
        //console.log (productsArray, 'products array');
        return (
            <div className="ProductGrid row no-gutter">
                {/*routeType == 'catalogue' ? itemsPerPageTemplate() : ''*/}
                {productsArray.map((product: any) => (
                    <div className="ProductGridItem col-6 col-md-3" key={product.id}>
                        {productPreviewTemplate(product, productsArray)}
                    </div>
                ))}
            </div>
        );
    };

    const noProductsTemplate = (): JSX.Element => {
        return (
            <div className="NoProducts">
                <div className="NoProductsText">{t('noProductsFoundCatalogue')}</div>
            </div>
        );
    };

    const productsTemplate = (): JSX.Element => {
        if(isLoading) {
            return <div style={{height:'50vh'}}>
                <Loader />
            </div>
        }
        if (products !== null && products.data !== null) {
            return productGridTemplate();
        } else {
            return noProductsTemplate();
        }
    };


    return (
        <div>
            <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={alertType}
                >{alertText}</Alert>
            </Collapse>
            {productsTemplate()}
            {confirmDeleteItemModal()}
            {renderMessageModal()}
        </div>
    );

}

export default withTranslation()(ProductsList);
