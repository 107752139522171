import React, {useEffect, useState} from 'react';
import jwt from 'jwt-decode';
import {useLocation, useNavigate, useParams} from "react-router";

import {frontendBaseURL, isJwtExpired, mainRolesRoutes, rolesCodes} from "../../global";
import {validateEmail, validatePassword} from "../../services/CheckUtils";
import dataProvider from "../../provider/api-interface";

import {
    FormControl,
    InputLabel,
    Input,
    Checkbox,
    FormHelperText,
    Button,
    TextField,
    Modal,
    Typography,
    Alert,
    AlertTitle,
    Collapse,
    IconButton, InputAdornment, Select, MenuItem
} from '@mui/material';
import Header from '../Header';
import './style.css';
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {Visibility} from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useSearchParams} from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface RegisterProps extends WithTranslation {
    role: string;
    routeRedirect: string;
}

function Register() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    if (
        (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined ||
            sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== undefined)
    ) {
        // console.log('ciao reload');
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    }

    const [token, setToken] = useState<string>('');
    const [email, setEmail] = useState('');
    const [civility, setCivility] = useState<any>(null);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState<boolean>(true);
    const [confirmPasswordText, setConfirmPasswordText] = useState<string>('');
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);

    const [salesAssistantId, setSalesAssistantId] = useState<string>('');

    const [productId, setProductId] = useState<string>('');
    const [listCode, setListCode] = useState<string>('');

    const [userRole, setUserRole] = useState<any>(null);

    const [termsStyle, setTermsStyle] = useState<any>({fontWeight: "400"});
    const [privacyStyle, setPrivacyStyle] = useState<any>({fontWeight: "400"});

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertText, setAlertText] = useState<any>(t('wrongCredentials'));

    const [showCivility, setShowCivility] = useState<boolean>(false);
    const [newAccountTitle, setNewAccountTitle] = useState<string>(t('newAccountTitle'));

    const [hideLanguageSelector, setHideLanguageSelector] = useState<boolean>(false);

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    const [termsPath, setTermsPath] = useState<string>('/documents/Terms_Conditions.pdf');
    const [privacyPath, setPrivacyPath] = useState<string>('/documents/Privacy_Policy.pdf');

    const location = useLocation();

    const titlesEN = [
        'Mr',
        'Mrs',
        'Miss',
        'Ms'
    ];

    const titlesFR = [
        'M.',
        'Mme',
        'Mlle'
    ];

    const [titles, setTitles] = useState<any>([]);

    /*const getBoutique = async (boutiqueId: any, token: any) => {
        await dataProvider.getOne('boutique', boutiqueId, token).then((boutique: any) => {
            console.log(boutique, 'boutique');
            if (boutique!== null) {
                console.log('boutique ok');
            }
        });
    };*/

    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
    }

    useEffect(() => {
        const getUser = async () => {
            if (!window.location.pathname.split('/')[2]) {
                // Check if md5 encoded email is present
                return false;
            }
            let token = window.location.pathname.split('/')[2];

            const user = await dataProvider.checkToken(token);

            if (!user) {

                // check if there is a redirect url
                let redirectURL = searchParams.get('redirectURL');
                if( redirectURL ) {
                    const url = new URL(redirectURL);
                    const params = new URLSearchParams(url.search);
                    params.delete("confirmToken");
                    url.search = params.toString();
                    window.location.href = url.toString();
                    return false;
                }
                else {
                    setAlertText(t('invalidToken'));
                    setAlertType("error");
                    setAlertOpen(true);
                    return false;
                }
            }

            if(user.role === 2) {
                if(user.created_by === 2) {
                    navigate('/login?confirmToken=' + token);
                    return false;
                }
                setNewAccountTitle(t('newAccountGifteeTitle'));
            }
            console.log(user, 'user')
            /*if(user.role === 3) {
                let wishlistProductId = searchParams.get('id');
                let listCode = searchParams.get('listCode');
                setListCode(listCode!)
                console.log(wishlistProductId, 'wishlistProductId');
                if(wishlistProductId !== null) {
                    setProductId(wishlistProductId);
                }
            }*/

            if(user.role === 2) {
                const diorLocaleId = user.dior_locale_id;
                if(parseInt(diorLocaleId) === 4) { //hide language selector for UAE with fixed english language
                    setHideLanguageSelector(true);
                    setLanguage('en');
                    i18n.language = 'en';
                    sessionStorage.setItem('locale', '2');
                    sessionStorage.setItem('hideLanguageSelect', 'true');
                    setTitles(titlesEN);
                    setTermsPath('/documents/Terms_Conditions_UAE.pdf');
                    setPrivacyPath('/documents/Privacy_Policy_UAE.pdf');
                } else {
                    if (user.locale_id === 1) {
                        setTitles(titlesFR);
                        setLanguage('fr_fr');
                    } else {
                        setTitles(titlesEN);
                        setLanguage('en_gb');
                    }
                    setHideLanguageSelector(true)
                    setShowCivility(true);
                    setCivility(user.civility);
                }
                //setSalesAssistantId(user.sales_assistant_id);
            }

            setToken(token);
            setEmail(user.email);
            setUserRole(user.role);
        };

        getUser();
    }, []);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsEmailValid(validateEmail(event.target.value));
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setIsPasswordValid(validatePassword(event.target.value));
        if (confirmPasswordText !== event.target.value) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("passwordsNotMatchingParagraph"));
            setConfirmPassword(false);
        } else {
            setAlertOpen(true);
            setAlertType("success");
            setAlertText(t("passwordsMatchingParagraph"));
            setConfirmPassword(true);
        }
    };

    const handleTermsAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setTermsStyle({fontWeight: "700"});
            setTermsAccepted(true);
        } else {
            setTermsStyle({fontWeight: "400"});
            setTermsAccepted(false);
        }
    }

    const handlePrivacyAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setPrivacyStyle({fontWeight: "700"});
            setPrivacyAccepted(true)
        } else {
            setPrivacyStyle({fontWeight: "400"});
            setPrivacyAccepted(false)
        }
    }

    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 2000);
    };

    const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (password !== event.target.value) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("passwordsNotMatchingParagraph"));
            setConfirmPasswordText(event.target.value)
            setConfirmPassword(false);
        } else {
            setAlertOpen(true);
            setAlertType("success");
            setAlertText(t("passwordsMatchingParagraph"));
            setConfirmPasswordText(event.target.value);
            setConfirmPassword(true);
        }
    }

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setButtonDisabled(true)
        if (email === '' || password === '') {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("emptyFieldsParagraph"));
            autoClose();
            setButtonDisabled(false);
            return;
        }

        /*if(userRole !== null && userRole === 2 && (!civility || civility === null)) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("emptyFieldsParagraph"));
            autoClose();
            setButtonDisabled(false);
            return;
        }*/

        if (!isPasswordValid) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("invalidPasswordParagraph"));
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if (!confirmPassword) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("passwordsNotMatchingParagraph"));
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if (!termsAccepted) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("termsNotAcceptedParagraph"));
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if (!privacyAccepted) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("privacyNotAcceptedParagraph"));
            setButtonDisabled(false);
            autoClose();
            return;
        }
        const jwtToken: any = await dataProvider.confirmRegister(token, password, userRole, civility);
        //console.log(jwtToken)
        if(jwtToken == null) {
            setAlertType("error");
            setAlertOpen(true);
            setAlertText(t('errorRegistering'));
            setButtonDisabled(false);
            autoClose();
            return false;
        }

        sessionStorage.setItem('token', jwtToken);

        let user: any = jwt(jwtToken);

        let roleCode: number = parseInt(user.role);
        let role = rolesCodes[roleCode];
        if(roleCode === 0 || roleCode === 1) {
            navigate(`/${mainRolesRoutes[role]}/`);
        } else {
            if(roleCode === 2) {
                dataProvider.getWishlistByGifteeId(user.id, jwtToken).then((wishlist: any) => {
                    if (wishlist.data !== null) {
                        console.log(wishlist, 'wishlist');
                        console.log('redirect to wishlist ok')
                        navigate(`/${mainRolesRoutes[role]}/registry/${wishlist.data[0].list_code}`);
                    } else {
                        console.log('redirect to login success ok')
                        navigate(`/${mainRolesRoutes[role]}/login-success`);
                    }
                });

            }

            /*if (roleCode === 3) {
                if(productId !== null) {
                    navigate(`/${mainRolesRoutes[role]}/registry/create-message?id=${productId}&listCode=${listCode}`);
                } else {
                    navigate(`/${mainRolesRoutes[role]}/404`);
                }
            }*/
        }

    };

    const renderCivility = () => {
        if(!showCivility) return null;
        return  <FormControl fullWidth className="CivilityContainer mt-4 mb-5">
            <InputLabel id="civility-select-label">{t('titleClientNameLabel')}</InputLabel>
            <Select
                MenuProps={{autoFocus: false}}
                labelId="civility-select-label"
                id="civility-select"
                value={civility}
                onChange={(e) => setCivility(e.target.value)}
                variant="standard"
                required={true}
                style={{width: '100%'}}
                IconComponent={ExpandMoreIcon}
            >
                {titles?.map((title: any, i: any) => (
                    <MenuItem
                        value={title}
                        key={i}
                        selected={title === civility}
                        style={{fontSize: '13px', fontFamily: 'Hellix'}}
                    >
                        {title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>


    }

    const SubmitButton = () => {
        if (email === ''
            || password === ''
            || !isPasswordValid
            || !confirmPassword
            || !termsAccepted
            || !privacyAccepted) {
            return  <button type="submit" disabled className="Fgift-button">
                {t('createAccountButton')}
            </button>;
        }
        return <button type="submit" className="Fgift-button">
            {t('createAccountButton')}
        </button>
    }

    return (
        <div>
            <Header /*hideLanguageSelect={hideLanguageSelector}*/ />
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                    <div className="FormTitle p-4"><h4>{newAccountTitle}</h4></div>
                </div>
                <div className="col-12 col-lg-6">
                    <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                        <Alert severity={alertType}
                               onClose={() => setAlertOpen(false)}
                               action={
                                   <IconButton
                                       aria-label="close"
                                       color="inherit"
                                       size="small"
                                       onClick={() => {
                                           setAlertOpen(false);
                                       }}
                                   >
                                       <CloseIcon fontSize="inherit" />
                                   </IconButton>
                               }
                        >
                            {alertText}
                        </Alert>
                    </Collapse>
                    <form className="LoginForm" onSubmit={handleFormSubmit}>
                        <div className="FormTitle d-block d-lg-none"><h4>{newAccountTitle}</h4></div>
                        <div className="EmailContainer">
                            <div className="FormLabel"><span>EMAIL</span></div>
                            <TextField
                                label={t("emailLabel")}
                                value={email}
                                disabled
                                error={!isEmailValid}
                                helperText={!isEmailValid && t('notValidMailHelper')}
                                variant="standard"
                            />
                        </div>
                        {renderCivility()}
                        <div className="PasswordContainer">
                            <div className="FormLabel"><span style={{textTransform: 'uppercase'}}>{t('createNewpassword')}</span></div>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label={t('passwordLabel')}
                                value={password}
                                onChange={handlePasswordChange}
                                error={!isPasswordValid}
                                helperText={!isPasswordValid && t('invalidPasswordParagraph')}
                                variant="standard"
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label={t('confirmPassword')}
                                onChange={handleConfirmPassword}
                                variant="standard"
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <div className="AcceptTermsContainer">
                                <Checkbox
                                    onChange={handleTermsAccepted}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    sx={{
                                        color: 'black',
                                        '&.Mui-checked': {
                                            color: 'black',
                                        },
                                    }}
                                /> <span style={termsStyle}>{t('acceptTermsAndConditionsText')} <a href={termsPath} target="_blank">{t('termsAndConditions')}</a></span>
                            </div>
                            <div className="AcceptPrivacyContainer">
                                <Checkbox
                                    onChange={handlePrivacyAccepted}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox'}}
                                    sx={{
                                        color: 'black',
                                        '&.Mui-checked': {
                                            color: 'black',
                                        },
                                    }}
                                /> <span style={privacyStyle}>{t('acceptPrivacyPolicyText')} <a href={privacyPath} target="_blank">{t('privacyPolicy')}</a></span>
                            </div>
                        </div>
                        <div className="ButtonContainer" style={{width: 'calc(100% - 40px) !important'}}>
                            <SubmitButton />
                        </div>
                    </form>
                {/*renderCivility()*/}
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
        </div>
    );
}

export default withTranslation()(Register);
