import React, { useState, useEffect, useMemo } from "react";
import Header from "../Header";
import {
    Button,
    Modal,
    TextField,
    Typography,
    Alert,
    IconButton,
    Collapse,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio, Autocomplete
} from "@mui/material";

import {checkPathName} from "../../global";
import jwt from "jwt-decode";
import dataProvider from "../../provider/api-interface";
import {useNavigate, useParams} from "react-router";

import {frontendBaseURL} from "../../global";

import {
    Box,
    Select,
    MenuItem,
    InputLabel,
    ListSubheader,
    InputAdornment
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation, withTranslation } from 'react-i18next';

import {
    LocalizationProvider,
    DatePicker,
} from "@mui/x-date-pickers";

import {
    AdapterDayjs,
} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import './CreateWishlist.css';

interface Giftee {
    id: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    boutique_id: string;
    id_dior_star: string;
}

function CreateWishlist() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const [pageType, setPageType] = React.useState<string>(window.location.pathname.split('/')[3]);


    const date = new Date();

    const [giftees, setGiftees] = useState<any>(null);
    const [wishlists, setWishlists] = useState<any>(null);

    const [gifteeName, setGifteeName] = useState<string>("");

    const [selectedGiftee, setSelectedGiftee] = useState("");
    const [eventName, setEventName] = useState<string>("");
    const [eventDate, setEventDate] = useState<number>(date.setDate(date.getDate() + 30));
    const [closingDate, setClosingDate] = useState<number>(date.setDate(date.getDate() + 180));
    //const [pickupAddress, setPickupAddress] = useState<string>("");
    const [pickupAddressSelected, setPickupAddressSelected] = useState<boolean>(true); //TODO set as false when pickup address is implemented
    const [shippingAddressSelected, setShippingAddressSelected] = useState<boolean>(false);

    const [shippingAddress, setShippingAddress] = useState<string>("");
    const [shippingApartment, setShippingApartment] = useState<string>("");
    const [shippingCity, setShippingCity] = useState<string>("");
    const [shippingZipCode, setShippingZipCode] = useState<string>("");
    const [shippingCountry, setShippingCountry] = useState<string>("");

    const [boutiqueId, setBoutiqueId] = useState<string>("");
    const [wishlistLocaleId, setWishlistLocaleId] = useState<any>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [existingWishilist, setExistingWishlist] = useState<any>(null);

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<any>("");
    const [alertType, setAlertType] = useState<"warning" | "success" | "error">("warning");
    //const [showPickupAddress, setShowPickupAddress] = useState<boolean>(false);
    const [showInsertAddressForm, setShowInsertAddressForm] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    const [openSelectClient, setOpenSelectClient] = React.useState(false);


    const radioStyle = {
        color: '#000',
            '&.Mui-checked': {
            color: '#000',
        },
    };

    //Use anywhere
    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere
    //console.log(selectedGiftee, 'selectedGiftee');
    const getGiftees = async () => {
        if(user.role === 0) {
            await dataProvider.getGifteesWithoutWishlists(user.locale_id, jwtToken).then((response: any) => {
                if(response !== null && response.data !== null) {
                    console.log(response);
                    setGiftees(response.data);
                } else {
                    setAlertText(t('noGiftees'));
                    setAlertType("warning");
                    setAlertOpen(true);
                }
            });
        }

        if(user.role === 1) {
            await dataProvider.getAllGifteesWithoutWishlist(jwtToken).then((response: any) => {
                if(response !== null && response.data !== null) {
                    console.log(response);
                    setGiftees(response.data);
                } else {
                    setAlertText(t('noGiftees'));
                    setAlertType("warning");
                    setAlertOpen(true);
                }
            });
        }
    }


    useEffect(() => {

        const wishlistId = window.location.pathname.split('/')[4];

        if(wishlistId !== undefined && wishlistId !== "") {
            setPageType('edit')
            const getWishlist = async () => {
                await dataProvider.getWishlistByListCode(wishlistId, jwtToken).then((data: any) => {
                    if (data !== null) {
                        setExistingWishlist(data);
                        setGifteeName(data.giftee.name + ' ' + data.giftee.surname);
                        setSelectedGiftee(data.giftee.id)
                        setEventName(data.name);
                        setEventDate(dayjs(data.start_date).valueOf());
                        setClosingDate(dayjs(data.end_date).valueOf());
                        //setPickupAddress(data.delivery_address_line_1);
                        /*setPickupAddressSelected(data.choiced_pickup_option === 1);
                        setShippingAddressSelected(data.choiced_pickup_option === 0);
                        setShowInsertAddressForm(data.choiced_pickup_option === 0);
                        setShippingAddress(data.delivery_address_line_1 !== 'null' ? data.delivery_address_line_1 : "");
                        setShippingApartment(data.delivery_address_line_2 !== 'null' ? data.delivery_address_line_2 : "");
                        setShippingCity(data.delivery_city !== 'null' ? data.delivery_city : "");
                        setShippingZipCode(data.delivery_zip_code !== 'null' ? data.delivery_zip_code : "");
                        setShippingCountry(data.delivery_country_or_region !== 'null' ? data.delivery_country_or_region : "");*/
                    } else {
                        setAlertText(t('wishlistError'));
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                });
            }
            getWishlist();
            setIsLoading(false);
        } else {
            getGiftees()
        }

    }, []);
    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };

    const checkDates = (eventDate: any, closingDate: any): boolean => {
        let eventDateNumber = parseInt(eventDate);
        let closingDateNumber = parseInt(closingDate);
        if(eventDateNumber === 0 || closingDateNumber === 0) {
            return false;
        }

        if(eventDateNumber > closingDateNumber) {
            return false;
        }

        return true;
    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setButtonDisabled(true);
        if (selectedGiftee === "") {
            setAlertText(t('selectGiftee'));
            setAlertType("warning");
            setAlertOpen(true);
            autoClose();
            setButtonDisabled(false);
            return;
        }

        if(eventName === "") {
            setAlertText(t('emptyEventName'));
            setAlertType("warning");
            setAlertOpen(true);
            autoClose();
            setButtonDisabled(false);
            return;
        }

        if(!checkDates(eventDate, closingDate)) {
            setAlertText(t('closingDateError'));
            setAlertType("warning");
            setAlertOpen(true);
            autoClose();
            setButtonDisabled(false);
            return;
        }

        if(!pickupAddressSelected && !shippingAddressSelected) {
            setAlertText("You must select a pickup address or insert a shipping address");
            setAlertType("warning");
            setAlertOpen(true);
            autoClose();
            setButtonDisabled(false);
            return;
        }

        /*if(pickupAddressSelected && pickupAddress === "") {
            setAlertText("You must select a pickup address");
            setAlertOpen(true);
            autoClose();
            return;
        }*/

        let shippingAddressObject = {};
        if(shippingAddressSelected && (shippingAddress === "" ||
            shippingCity === "" ||
            shippingZipCode === "" ||
            shippingCountry === "")) {
            setAlertText("You must insert a valid shipping address");
            setAlertType("warning");
            setAlertOpen(true);
            setButtonDisabled(false);
            return;
        } else if(shippingAddressSelected) {
            shippingAddressObject = {
                country: shippingCountry,
                address: shippingAddress,
                apartment: shippingApartment,
                city: shippingCity,
                zipCode: shippingZipCode
            }
        }

        let formattedEventDate = dayjs(eventDate).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        let formattedClosingDate = dayjs(closingDate).format('YYYY-MM-DDTHH:mm:ss.sssZ');

        /*let wishlistObject = {
            name: eventName,
            start_date: formattedEventDate,
            end_date: formattedClosingDate,
            choiced_pickup_option: !pickupAddressSelected ? 0 : 1,
            delivery_address_line_1: !shippingAddressSelected ? null : shippingAddress,
            delivery_address_line_2: !shippingAddressSelected ? null : shippingApartment,
            delivery_city: !shippingAddressSelected ? null : shippingCity,
            delivery_zip_code: !shippingAddressSelected ? null : shippingZipCode,
            delivery_country_or_region: !shippingAddressSelected ? null : shippingCountry,
            giftee_id: selectedGiftee,
            sales_assistant_id: user.id,
            boutique_id: user.boutique_id
        }*/

        let created_by = 0;
        if(user.role === 1) {
            created_by = 1;
        }

        let wishlistObject: any = {
            name: eventName,
            start_date: formattedEventDate,
            end_date: formattedClosingDate
        }

        if(pageType !== 'edit') {
            wishlistObject.created_by = created_by;
            wishlistObject.csc_id = null;
            wishlistObject.dior_locale_id = wishlistLocaleId;
            wishlistObject.choiced_pickup_option = !pickupAddressSelected ? 0 : 1;
            wishlistObject.boutique_id = boutiqueId;
            wishlistObject.giftee_id = selectedGiftee;
        }

        if(pageType !== 'edit' && user.role === 0) {
            wishlistObject.sales_assistant_id = user.id;
        } else if(pageType !== 'edit' && user.role === 1) {
            wishlistObject.sales_assistant_id = null;
            wishlistObject.csc_id = user.id;
        }

        if(pageType === 'edit') {
            wishlistObject.boutique_id = existingWishilist.boutique_id;
        }
        //console.log(wishlistObject);
        if(pageType === 'edit' && existingWishilist !== null) {
            const wishlistId = window.location.pathname.split('/')[4];
            const updateWishlist = async () => {
                let objectToUpdate = {
                    wishlistParams: wishlistObject
                };
                let response = await dataProvider.update('wishlist', existingWishilist.id, objectToUpdate, jwtToken);
                if(response !== null) {
                    setAlertText(t('giftListUpdateSuccess'));
                    setAlertType("success");
                    setAlertOpen(true);
                    let link = `/${pathName}/`;
                    if(user.role === 2 && existingWishilist !== null) { //back link for giftee
                        link = `/giftee/registry/${existingWishilist.list_code}`;
                    }
                    setTimeout(() => {
                        navigate(link);
                    }, 2000);
                } else {
                    setAlertText(t('giftListUpdateError'));
                    setAlertType("error");
                    setButtonDisabled(false);
                    setAlertOpen(true);
                }
            }

            updateWishlist();
            return;
        }
        const createWishlist = async () => {
            let response = await dataProvider.create('wishlist', wishlistObject, jwtToken);
            if(response.data !== null) {
                setAlertText(t('giftListCreateSuccess'));
                setAlertType("success");
                setAlertOpen(true);
                setTimeout(() => {
                    navigate(`/${pathName}/`);
                }, 2000);
            } else {
                setAlertText(t('giftListCreateError'));
                setAlertType("error");
                setButtonDisabled(false);
                setAlertOpen(true);
            }
        }

        createWishlist();
    };

    const handleAddressInputSelection = () => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            if(event.target.value === "insert") {
                //setShowPickupAddress(false);
                setPickupAddressSelected(false);
                setShippingAddressSelected(true);
                setShowInsertAddressForm(true);
            } else {
                 //setShowPickupAddress(true);
                 setPickupAddressSelected(true);
                 setShippingAddressSelected(false);
                 setShowInsertAddressForm(false);
             }
        }
    }

    const handleInsertAddress = (type: any) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            if(type === "country") {
                setShippingCountry(event.target.value);
            } else if(type === "address") {
                setShippingAddress(event.target.value);
            } else if(type === "apartment") {
                setShippingApartment(event.target.value);
            } else if(type === "city") {
                setShippingCity(event.target.value);
            } else if(type === "zipCode") {
                setShippingZipCode(event.target.value);
            }
        }
    }

    /*const pickupAddressTemplate = () => {
        if(showPickupAddress) {
            return  (
                <div className="WishlistPickupAddressContainer">
                    <FormControl onChange={handlePickupAddress()}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            {boutiques?.map((boutique) => (
                                <FormControlLabel
                                    value={boutique.id}
                                    control={<Radio sx={radioStyle} />}
                                    label={
                                        <span>{boutique.address_line_1} {boutique.city} {boutique.zip_code} {boutique.country_or_region}</span>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </div>
            );
        }

        return "";
    };*/

    const containsText = (text: string, searchText: string): boolean =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

    const [searchText, setSearchText] = useState("");

    const selectGifteeTemplate = () => {
        const wishlistId = window.location.pathname.split('/')[4];
        if(wishlistId !== undefined && wishlistId !== "") {
            return (
                <div className="WishlistGifteeNameContainer" style={{display: 'contents'}}>
                    <TextField
                        label={t('clientNameLabel')}
                        value={gifteeName}
                        disabled={true}
                        variant="standard"
                    />
                </div>
            );
        }

        return (
            <Autocomplete
                options={giftees !== null ? giftees : []}
                disabled={giftees === null}
                getOptionLabel={(giftee: any) => `${giftee.name} ${giftee.surname}`}
                value={giftees.find((giftee: any) => giftee.id === selectedGiftee) || null}
                onChange={(event, newValue) => {
                    setSelectedGiftee(newValue?.id || "");
                    setBoutiqueId(newValue?.boutique_id || "");
                    setWishlistLocaleId(newValue?.boutique.dior_locale_id || null);
                }}
                renderInput={(params: any) => (
                    <TextField
                        helperText={giftees === null ? t('noGifteesAvailable') : ''}
                        showHelperText={giftees === null}
                        {...params}
                        label={t('clientNameLabel')}
                        variant="standard"
                    />
                )}
                renderOption={(props, giftee: any) => (
                    <div key={props.id}>
                        <Box
                            {...props}
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        >
                            <Typography>{giftee.name} {giftee.surname}</Typography>
                        </Box>
                    </div>
                )}
            />
        );


    }
    //END SELECT GIFTEE

    //START ADDRESS ACCORDION
    const insertAddressFormTemplate = () => {
        if(showInsertAddressForm) {
            return (
                <div className="WishlistShippingFormContainer">
                    <TextField
                        label={t('countryLabel')}
                        value={shippingCountry}
                        onChange={handleInsertAddress("country")}
                        required={true}
                        variant="standard"
                    />
                    <TextField
                        label={t('streetLabel')}
                        value={shippingAddress}
                        onChange={handleInsertAddress("address")}
                        required={true}
                        variant="standard"
                    />
                    <TextField
                        label={t('aptLabel')}
                        value={shippingApartment}
                        onChange={handleInsertAddress("apartment")}
                        required={false}
                        variant="standard"
                    />
                    <TextField
                        label={t('cityLabel')}
                        value={shippingCity}
                        onChange={handleInsertAddress("city")}
                        required={true}
                        variant="standard"
                    />
                    <TextField
                        label={t('zipCodeLabel')}
                        value={shippingZipCode}
                        onChange={handleInsertAddress("zipCode")}
                        required={true}
                        variant="standard"
                    />
                </div>
            )
        }

        return "";
    }

    const AddressAccordion = () => {
        return (
            <div className="WishlistShippingAccordionContainer">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>* {t('shipping')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="WishlistSelectShippingContainer">
                            <FormControl onChange={handleAddressInputSelection()}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="pickup" checked={pickupAddressSelected} control={<Radio sx={radioStyle} />} label={t('pickupLabel')} />
                                    <FormControlLabel value="insert" checked={shippingAddressSelected} control={<Radio sx={radioStyle} />} label={t('addShippingLabel')} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {/*pickupAddressTemplate()*/}
                        {insertAddressFormTemplate()}
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };
    //END ADDRESS ACCORDION

    let link = `/${pathName}/`;
    if(user.role === 2 && existingWishilist !== null) { //back link for giftee
        link = `/giftee/registry/${existingWishilist.list_code}`;
    }
    return (
        <div style={{height:'100vh'}}>
            <Header link={link} textLink={'Back to Dashboard'} />
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="col-12 col-lg-6">
                    <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity={alertType}
                        >{alertText}</Alert>
                    </Collapse>
                    <form className="WishlistForm" onSubmit={handleSubmit}>
                <div className="WishlistTitle">
                    <h4>{t("createWishlistTitle")}</h4>
                </div>
                {giftees !== null ? selectGifteeTemplate() : ""}
                <TextField
                    className="WishlistNameField"
                    label={t('eventNameLabel')}
                    value={eventName}
                    onChange={(event) => {
                        setEventName(event.target.value);
                    }}
                    variant="standard"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={t('eventDateLabel')}
                        value={dayjs(eventDate)}
                        onChange={(newValue) => {
                            let datesToCheck = (newValue === null) ? 0 : dayjs(newValue).valueOf();
                            setEventDate(datesToCheck)
                            if(!checkDates(datesToCheck, closingDate)) {
                                setAlertOpen(true);
                                setAlertText(t('closingDateError'))
                                setAlertType("warning");
                                autoClose();
                            }
                        }}
                        format={'DD/MM/YYYY'}
                        slotProps={{ textField: { variant: 'standard' } }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={t('closingDateLabel')}
                        value={dayjs(closingDate)}
                        onChange={(newValue) => {
                            let datesToCheck = (newValue === null) ? 0 : dayjs(newValue).valueOf();
                            setClosingDate(datesToCheck)
                            if(!checkDates(eventDate, datesToCheck)) {
                                setAlertOpen(true);
                                setAlertText(t('closingDateError'))
                                setAlertType("warning")
                                autoClose();
                            }
                        }}
                        format={'DD/MM/YYYY'}
                        slotProps={{ textField: { variant: 'standard' } }}
                    />
                </LocalizationProvider>
                {/*AddressAccordion()*/}
                <div className="ButtonContainer">
                    <button disabled={buttonDisabled} type="submit" className="Fgift-button">
                        {pageType === 'edit' ? t('editWishlistButton') : t('createWishlistButton')}
                    </button>
                </div>
            </form>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(CreateWishlist);
