import React, {useEffect} from 'react';
import { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import jwt from "jwt-decode";
import {checkPathName} from "../../global";
import dataProvider from "../../provider/api-interface";
import {useNavigate} from "react-router";

import './GifteesPreview.css';
import {OverlayTrigger, Popover, PopoverBody} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Loader} from '../Loader';

import {
    Close,
    Check
} from "@mui/icons-material";

import EditIcon from '../../media/images/Edit.svg';
import PhoneIcon from '../../media/images/Phone.svg';
import MailIcon from '../../media/images/Mail.svg';

interface Giftee {
    id: string;
    name: string;
    surname: string;
    email: string;
    phone_number: string;
    dior_star_id: string;
    registered: boolean;
    acceptance_condition_settings: number;
    acceptance_privacy_and_terms: number;
    created_at: string;
    updated_at: string;
}

function GifteesPreview(props: any) {
    const { t, i18n } = useTranslation();
    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const navigate = useNavigate();

    const giftees = props.giftees;
    const isLoading = props.isLoading;

    const [notificationOpen, setNotificationOpen] = useState(false);

    //Use anywhere
    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    const autoClose = () => {
        setTimeout(() => {
            setNotificationOpen(false);
        }, 3000);
    };

    const editGiftee = (giftee: Giftee) => {
        //if(parseInt(user.role) === 1){ //CSC can't edit giftee
        //    return <span></span>;
        //}

        return <div className="GifteeMoreContainer" style={{cursor: 'pointer'}}>
            <Link to={`/${pathName}/giftee/edit/${giftee.id}`}>
                {t('edit')} <img src={EditIcon} />
            </Link>
        </div>
    }

    const gifteeTemplate = (giftee: Giftee, index: number) => {
        return (
            <div key={index} className="GifteeContainer">
                <div className="row">
                    <div className="col-6">
                        <div className="GifteeNameContainer">
                            <h4>{giftee.name} {giftee.surname}</h4>
                        </div>
                    </div>
                    <div className="col-6">
                        {editGiftee(giftee)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="GifteeEmailContainer">
                            <p><img src={MailIcon} /> {giftee.email}</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="GifteePhoneContainer">
                            <p><img src={PhoneIcon} /> {giftee.phone_number}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="GifteeTermsAcceptedContainer">
                            <p>{giftee.acceptance_condition_settings === 1 ? <Check fontSize="small" /> : <Close fontSize="small" />} {t("termsAndConditions")}</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="GifteePrivacyAcceptedContainer">
                            <p>{giftee.acceptance_privacy_and_terms === 1 ? <Check fontSize="small" />  : <Close fontSize="small" />} {t("privacyPolicy")}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const noClients = () => {
        return (
            <div className="NoGifteesContainer">
                <h4 style={{ textTransform: 'uppercase' }}>{t('noClientH4')}</h4>
                <p>{t('noClientP')}</p>
            </div>
        );
    };

    const gifteesTemplate = () => {
        if (giftees === null) {
            return noClients();
        } else {
            return giftees.map((giftee: any, index: number) => {
                return gifteeTemplate(giftee, index);
            });
        }
    };

    if(isLoading) {
        return <Loader />
    }

    return <div className="GifteesContainer">
                    {gifteesTemplate()}
            </div>;
}

export default withTranslation()(GifteesPreview);
