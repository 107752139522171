import React, {useEffect, useState} from 'react';
//import { login } from '../../services/authService';
import jwt from 'jwt-decode';

import { useLocation, useNavigate } from "react-router";
import {useSearchParams} from "react-router-dom";


import {rolesCodes, mainRolesRoutes, apiBaseURL, isJwtExpired} from "../../global";

import {
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    Button,
    TextField,
    Modal,
    Typography,
    Alert,
    AlertTitle,
    Collapse,
    IconButton,
    InputAdornment, Checkbox
} from '@mui/material';
import Header from '../Header';
import './style.css';
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {Visibility, VisibilityOutlined, VisibilityOffOutlined} from "@mui/icons-material";
import dataProvider from "../../provider/api-interface";

function GifterLogin() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [wishlistId, setWishlistId] = useState<string>('');
    const listCodePathName = window.location.pathname.split('/')[2];
    const [listCode, setListCode] = useState<string>(listCodePathName ? listCodePathName : '');

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [formTitle, setFormTitle] = useState<string>("Gift list Login");
    const handleClickShowPassword = () => setShowPassword(!showPassword);


    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertText, setAlertText] = useState<any>(t('wrongCredentialsGiftersParagraph'));

    const [hideLanguageSelect, setHideLanguageSelect] = useState<boolean>(false);
    const [languageLocale, setLanguageLocale] = useState<string | null>(null);

    const location = useLocation();

    useEffect(  () => {
        console.log(listCodePathName, 'listCodePathName');
        if(listCode !== '') {
            console.log(listCode, 'listCode');
            dataProvider.checkIfWishlistExists(listCode).then((wishlist: any) => {
                if (wishlist !== null) {
                    console.log(wishlist);
                    setWishlistId(wishlist.list_code);
                    setFormTitle(wishlist.name);

                    if(parseInt(wishlist.dior_locale_id) === 4) {
                        i18n.changeLanguage('en');
                        setHideLanguageSelect(true);
                        i18n.language = 'en';
                        setLanguageLocale('en');
                        sessionStorage.setItem('locale', '2');
                        sessionStorage.setItem('hideLanguageSelect', 'true');
                    }

                    let jwtToken: any = null;

                    if (localStorage.getItem('token') !== null) {
                        jwtToken = localStorage.getItem('token');
                    } else if (sessionStorage.getItem('token') !== null) {
                        jwtToken = sessionStorage.getItem('token');
                    }

                    if (
                        jwtToken !== null
                    ) {
                        console.log(jwtToken);
                        if (!isJwtExpired(jwtToken)) {
                            let user: any = jwt(jwtToken!);
                            let roleCode: number = parseInt(user.role);
                            let role = rolesCodes[roleCode];
                            navigate(`/${mainRolesRoutes[role]}/registry/${listCode}`);
                        }
                    }

                } else {
                    console.log("No birthlist found");
                }
            }).catch((error: any) => {
                console.log("No birthlist found" + error);
            });
        }
    }, []);

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let response: any = await dataProvider.gifterLogin(password, listCode);

        if(response === null) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("wrongCredentialsGiftersParagraph"));
            return;
        }
        //console.log(response);
        const token: string = response.token;
        localStorage.setItem('token', token);
        console.log(token);
        let user: any = jwt(token);
        let roleCode: number = parseInt(user.role);
        let role = rolesCodes[roleCode];

        if (roleCode === 3) {
            if(wishlistId !== null) {
                navigate(`/${mainRolesRoutes[role]}/registry/${listCode}`);
            } else {
                navigate(`/404`);
            }
        } else {
            navigate(`/404`);
        }
    };

    return (
        <div>
            <Header hideLanguageSelect={hideLanguageSelect} languageLocale={languageLocale} />
            <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                <Alert severity={alertType}
                       onClose={() => setAlertOpen(false)}
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setAlertOpen(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit" />
                           </IconButton>
                       }
                >
                    {alertText}
                </Alert>
            </Collapse>
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="col-12 col-lg-6">
                    <form className="LoginForm" onSubmit={handleFormSubmit}>
                <div className="FormContainer GifterForm">
                    <div className="FormTitle GifterLoginTitle"><h4>{formTitle}</h4></div>
                    <TextField
                        type={showPassword ? "text" : "password"}
                        label={t('passwordGiftList')}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        variant="standard"
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ? <VisibilityOutlined style={{width: '18px'}} /> : <VisibilityOffOutlined style={{width: '18px'}} />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div className="ButtonContainer GifterLoginButtonContainer">
                    <button type="submit" className="Fgift-button">
                        {t('giftListLogin')}
                    </button>
                </div>
            </form>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(GifterLogin);
